.rs-steps.rs-steps-small.rs-steps-horizontal {
  margin: 0 auto;
  max-width: 700px;
}

.rs-steps-item-status-finish .rs-steps-item-tail,
.rs-steps-item-status-finish .rs-steps-item-title:after {
  border-color: #fcbc00;
}

.rs-steps-item-status-finish
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color: #fcbc00;
}

.rs-steps-item-status-process
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color: #fcbc00;
  background: #fcbc00;
}

.steps {
  margin: 75px auto;
  max-width: 700px;
  padding: 15px;
  border: solid 1px #efefef;
}

@media only screen and (max-width: 600px) {
  .rs-steps-item-title {
    font-size: 12px;
  }
  .steps {
    margin: 25px auto;
  }
}
