.item-return-table {
  text-align: left;
  margin: 0 auto;
  margin-bottom: 25px;
  margin-top: 25px;
}

.item-return-table td {
  padding: 15px;
  background: #fbfbfb;
}

.item-return-table th {
  padding: 15px;
}

.item-return-table tr {
  border-bottom: 5px solid white;
}

textarea {
  font-size: 14px;
  margin-top: 10px;
}

.return-note {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px;
  padding-bottom: 0;
}

.product-name {
  display: block;
  margin-left: 40px;
}

.already-refunded {
  font-weight: 600;
  margin-left: 0;
  padding-left: 0;
  color: red;
  margin-bottom: 5px;
  display: block;
}

.return-status {
  color: green;
}

@media only screen and (max-width: 1024px) {
  .item-return-table {
    text-align: center;
    width: 75%;
    display: block;
    margin: 0 auto;
    margin-top: 50px;
  }

  .item-return-table td {
    display: block;
    text-align: center;
  }

  .item-return-table,
  .item-return-table td {
    min-width: 280px;
    font-size: 12px;
  }

  .item-return-table tr {
    border-bottom: 25px solid white;
    display: inline-block;
    vertical-align: top;
    border-right: 25px solid #fff;
  }
}
