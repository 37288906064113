.footer {
  border-top: solid 1px #efefef;
  margin-top: 25px;
  padding: 25px;
  bottom: 0;
  width: 100%;
  text-align: left;
}

ul.list-contacts-footer {
  list-style-type: none;
}

ul.list-contacts-footer li {
  margin-bottom: 10px;
  margin-left: -2.5em;
}

ul.list-contacts-footer li strong {
  margin-right: 10px;
}

.footer h4 {
  text-transform: uppercase;
  margin-bottom: 15px;
  padding-left: 0;
}

@media only screen and (max-width: 600px) {
  ul.list-contacts-footer li strong {
    display: block;
  }

  .footer {
    text-align: center;
  }
}
