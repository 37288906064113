/* .return-popup {
  position: absolute;
  top: 25%;
  left: 50%;
  right: 50%;
  width: 500px;
  background: black;
  border: solid 10px orange;
  padding: 15px;
  color: white;
  font-size: 18px;
}

.return-popup button {
  background: white;
  color: black;
  font-weight: 600;
  padding: 10px 15px;
} */

.rs-modal-content,
.rs-modal-header,
.rs-modal-body {
  color: black;
}

.rs-modal-body {
  padding: 15px;
  margin-top: 0;
}

.rs-modal-header .rs-modal-title {
  font-weight: 600;
  white-space: initial;
  text-overflow: initial;
}

.rs-modal-body p {
  margin-bottom: 15px;
}

.rs-modal-sm {
  max-width: 600px;
  width: auto;
}
