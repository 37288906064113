body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Poppins",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  text-align: left;
}

h1,
h2 {
  text-transform: uppercase;
  font-weight: 400;
}

h2 {
  background: orange;
  padding: 15px;
  color: white;
  line-height: 25px;
  font-size: 24px;
}

h3 {
  text-transform: uppercase;
}

h3,
h4 {
  padding: 15px;
  font-weight: 400;
}

.container {
  text-align: center;
  border-top: solid 5px #fcbc00;
}

button.btn.btn-primary {
  background: white;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  transition: 0.5s all;
  border: solid 1px black;
}

button.btn.btn-primary:hover {
  background: #fcbc00;
}

button.btn-primary.btn-confirm {
  background: black;
  color: white;
}

table {
  margin: 0 auto;
}

.header {
  background: #efefef;
}

select {
  font-size: 14px;
}

.content {
  margin-bottom: 50px;
}

.rs-btn-toolbar {
  margin-top: 75px;
}

@media only screen and (max-width: 1024px) {
  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 18px;
  }

  h3,
  h4 {
    padding: 15px;
    font-weight: 400;
  }

  button,
  input,
  select,
  textarea {
    font-size: 12px;
  }

  .rs-btn-toolbar {
    margin-top: 25px;
  }
}
