/* Customize the label (the container) */
.field {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.field input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -10px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: solid black;
  margin-top: 8px;
}

/* On mouse-over, add a grey background color */
.field:hover input[type="checkbox"] ~ .checkmark {
  background-color: #fcbc00;
}

/* When the checkbox is checked, add a blue background */
.field input[type="checkbox"]:checked ~ .checkmark {
  background-color: #fcbc00;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.field input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.field .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
