/* .customer input {
  padding: 25px;
  margin: 25px;
  border-radius: 5px;
  text-align: center;
} */

.customer {
  margin-top: 75px;
}
.customer input {
  text-align: center;
}
.rs-input {
  width: 300px !important;
  padding: 20px !important;
  margin: 0 auto;
}