.rs-header {
  text-align: center;
}

.header {
  min-height: 50px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  vertical-align: middle;
  width: 100%;
  color: black;
  text-align: left;
}

.app-title {
  font-weight: 300;
  font-size: 24px;
  vertical-align: middle;
  margin: 0 auto;
  display: inline-block;
  padding: 10px;
  padding-bottom: 0;
}

.header img {
  vertical-align: middle;
}

.return-to-strive {
  float: right;
  font-size: 16px;
  padding: 10px;
  color: black;
}

.header .rs-btn {
  margin: 20px;
  float: right;
}

@media only screen and (max-width: 600px) {
  .rs-header img {
    margin: 10px auto;
    display: block;
  }

  .rs-header h1 {
    text-align: center;
    width: 100%;
  }
}
