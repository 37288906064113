.rma-box {
  border: solid 1px black;
  background: white;
  font-weight: 600;
  font-size: 18px;
  max-width: 300px;
  margin: 35px auto;
}

.rma-box p {
  font-size: 30px;
}

.sub-header {
  font-weight: 600;
}

.warning {
  color: red;
  font-weight: 600;
  text-transform: uppercase;
}

.btn-success {
  margin-top: 25px;
}

.return-method {
  margin: 25px auto;
}

.return-methods {
  padding: 15px 0;
  border-bottom: solid 1px;
  border-top: solid 1px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.return-methods h4 {
  margin-top: 15px;
}

.success {
  margin-top: 50px;
}
