.faulty-grant-notice {
  color: red;
  max-width: 400px;
}

table.item-return-table.confirm-table {
  text-align: left;
  display: table;
  margin-top: 15px;
  max-width: 900px;
}

.confirm-table tr {
  width: 100%;
  display: inherit;
  border-right: none;
}

.confirm-table td {
  text-align: left;
  min-width: auto;
  display: table-cell;
}

.confirm-table tr:first-of-type {
  border-bottom: none;
}

@media only screen and (max-width: 1024px) {
  table.item-return-table.confirm-table {
    max-width: 100%;
    width: 100%;
  }
}
